import { Helmet } from "react-helmet";
import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Icon from "react-feather";
import { toast } from 'react-toastify';

import Sectiontitle from "../components/Sectiontitle";
import Layout from "../components/Layout";

function Contact() {
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [emailAddress, setEmailAddress] = useState([]);
  const [address, setAddress] = useState([]);
   const [formData, setFormData] = useState({ name: "", email: "", message: "" });
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

	const saveCustomerQueryDetails = async (payload) => {
		const headers = { 'x-api-key': `${process.env.REACT_APP_CONTACT_US_API_KEY}` };
		axios
			.post(`${process.env.REACT_APP_CONTACT_US_API_URL}/contact-us`, payload, { headers })
			.then((response) => {
				toast.success('Thank you for your request! I will get in touch you shortly.');
				setFormData({ name: "", email: "", message: ""});
			})
			.catch((error) => {
				toast.error('Oops! Something went wrong. Please email me with your request.');
			});
	};

  const submitHandler = async (event) => {
    event.preventDefault();
    if (!formData.name) {
      setError(true);
      setMessage("Name is required");
    } else if (!formData.email) {
      setError(true);
      setMessage("Email is required");
    } else if (!formData.message) {
      setError(true);
      setMessage("Message is required");
    } else {
      setError(false);
			await saveCustomerQueryDetails(formData);
    }
  };
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };
  const numberFormatter = (number) => {
    const phnNumber = number;
    return phnNumber;
  };

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4">{message}</div>;
    } else {
      return null;
    }
  };

  useEffect(() => {
    axios.get("/api/contactinfo").then((response) => {
      setPhoneNumbers(response.data.phoneNumbers);
      setEmailAddress(response.data.emailAddress);
      setAddress(response.data.address);
    });
  }, []);

  return (
		<Layout>
			<Helmet>
				<title>Contact - Learn And Share Live</title>
				<meta name="description" content="Learn And Share Live Contact Page" />
			</Helmet>
			<div className="mi-contact-area mi-section mi-padding-top mi-padding-bottom">
				<div className="container">
					<Sectiontitle title="Contact Me" />
					<div className="row">
						<div className="col-lg-6">
							<div className="mi-contact-formwrapper">
								<h4>Get In Touch</h4>
								<form
									action="#"
									className="mi-form mi-contact-form"
									onSubmit={submitHandler}
								>
									<div className="mi-form-field">
										<label htmlFor="contact-form-name">Enter your name*</label>
										<input
											onChange={handleChange}
											type="text"
											name="name"
											id="contact-form-name"
											value={formData.name}
										/>
									</div>
									<div className="mi-form-field">
										<label htmlFor="contact-form-email">
											Enter your email*
										</label>
										<input
											onChange={handleChange}
											type="text"
											name="email"
											id="contact-form-email"
											value={formData.email}
										/>
									</div>
									{/* <div className="mi-form-field">
										<label htmlFor="contact-form-subject">
											Enter your subject*
										</label>
										<input
											onChange={handleChange}
											type="text"
											name="subject"
											id="contact-form-subject"
											value={formData.subject}
										/>
									</div> */}
									<div className="mi-form-field">
										<label htmlFor="contact-form-message">
											Enter your Message*
										</label>
										<textarea
											onChange={handleChange}
											name="message"
											id="contact-form-message"
											cols="30"
											rows="6"
											value={formData.message}
										></textarea>
									</div>
									<div className="mi-form-field">
										<button className="mi-button" type="submit">
											Send Mail
										</button>
									</div>
								</form>
								{handleAlerts()}
							</div>
						</div>
						<div className="col-lg-6">
							<div className="mi-contact-info">
								{!phoneNumbers ? null : (
									<div className="mi-contact-infoblock">
										<span className="mi-contact-infoblock-icon">
											<Icon.Phone />
										</span>
										<div className="mi-contact-infoblock-content">
											<h6>Phone</h6>
											{phoneNumbers.map((phoneNumber) => (
												<p key={phoneNumber}>
													<a href={numberFormatter(phoneNumber)}>
														{phoneNumber}
													</a>
												</p>
											))}
										</div>
									</div>
								)}
								{!emailAddress ? null : (
									<div className="mi-contact-infoblock">
										<span className="mi-contact-infoblock-icon">
											<Icon.Mail />
										</span>
										<div className="mi-contact-infoblock-content">
											<h6>Email</h6>
											{emailAddress.map((email) => (
												<p key={email}>
													<a href={`mailto:${email}`}>{email}</a>
												</p>
											))}
										</div>
									</div>
								)}
								{!phoneNumbers ? null : (
									<div className="mi-contact-infoblock">
										<span className="mi-contact-infoblock-icon">
											<Icon.MapPin />
										</span>
										<div className="mi-contact-infoblock-content">
											<h6>Address</h6>
											<p>{address}</p>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export default Contact;
