import Mock from "../mock";

const database = {
	information: {
		name: 'Vikash Choudhary',
		aboutContent:
			'Bonjour Everyone! I am a sports enthusiastic person but when I realized I can not make it to any of my loving sports, started pursuing my career in IT. I have a total of 9+ years of experience. During this journey, I have worked on several domains like GRC, Finance, and IoT. For the last 3 years, I am working as a Full-stack engineer. Apart from this, I love contributing to the respective society in any form be it a helping hand for the needy, NGO work, sharing any form of relevant knowledge, etc. I love to play a few sports but primarily Badminton, Cricket, Football, Volleyball. Last but not the least I love cooking ... :)',
		// age: 30,
		phone: '7980735635',
		nationality: 'Indian',
		language: 'English, Hindi',
		email: 'servikash@gmail.com',
		address: 'Bengaluru, India',
		// freelanceStatus: 'Available',
		socialLinks: {
			facebook: '',
			stackoverflow:
				'https://stackoverflow.com/users/9375172/vikash-kumar-choudhary',
			github: 'https://github.com/VikashChoudahry',
			linkedin: 'https://www.linkedin.com/in/vikashkrchoudhary/',
			twitter: 'https://twitter.com/Vikash0219',
			pinterest: '',
			behance: '',
			dribbble: '',
		},
		brandImage: '/images/brand-image-v1.jpg',
		aboutImage: '/images/about-image.jpg',
		aboutImageLg: '/images/about-image-lg.jpg',
		cvfile: '/files/Vikash_Choudhary_Full_Stack_Developer.pdf',
	},
	services: [
		{
			title: 'Trainer',
			icon: 'mobile',
			details:
				'I love sharing my knowledge! The idea behind sharing my experiences is to ensure that engineers like me should not face the same problem that I have gone through. If need sort of training please let me know. No fees unless you wanna contribute :)',
		},
		{
			title: 'Automation',
			icon: 'mobile',
			details:
				'I love automation! Beat website, tooling, etc. Are you doing repeated tasks? If so then do reach out to me. I believe, I should be able to suggest a possible solution towards automation. Quality you decide!!',
		},
		{
			title: 'Web Development',
			icon: 'code',
			details:
				'I love digitization. I love creating web platform for the asked requirements. The recent one can be seen from here - https://www.rojgarapp.info/home. But certainly I can do beyond that.',
		},
		{
			title: 'Web Design',
			icon: 'color-pallet',
			details:
				'In a free time, I do make websites and so far I have delivered a couple of websites to the NGO as a contribution. The recent work can seen from here - https://dreamscometruengo.org/',
		},
	],
	reviews: [
		{
			id: 1,
			content: 'Thanks a lot. Everything worked like a charm :)',
			author: {
				name: 'Ashwin Ramakrishnan',
				designation: 'Viewer',
			},
		},
		{
			id: 2,
			content:
				'Awesome content Vikash. Thanks you so much for making this and clearing our doubts.',
			author: {
				name: 'Tanzeel Mirza',
				designation: 'Viewer',
			},
		},
		{
			id: 3,
			content: 'Hi, thanks for this! I learned a lot!',
			author: {
				name: 'Vanessa Dizon',
				designation: 'Viewer',
			},
		},
	],
	skills: [
		{
			title: 'Node.js',
			value: 85,
		},
		{
			title: 'Angular',
			value: 85,
		},
		{
			title: 'Javascript',
			value: 85,
		},
		{
			title: 'Database (Oracle, MySQL, PostgreSQL, MongoDB)',
			value: 85,
		},
		{
			title: 'Camunda (Workflow & decision automation platform)',
			value: 80,
		},
		{
			title: 'DevSecOps & Automation (Sonarqube, CSPM, Code Scan, ZAP)',
			value: 80,
		},
		{
			title: 'Cloud Platform (AWS)',
			value: 80,
		},
		{
			title: 'Additional Skills (Docker, Jenkins, Git)',
			value: 80,
		},
		{
			title: 'ReactJS',
			value: 60,
		},
	],
	portfolios: [
		{
			id: 1,
			title: 'T-shirt Mockup',
			subtitle: 'A beautiful t-shirt mockup.',
			imageUrl: '/images/portfolio-image-1.jpg',
			largeImageUrl: ['/images/portfolio-image-1-lg.jpg'],
			url: 'https://dribbble.com',
		},
		{
			id: 2,
			title: 'Coffee Mug',
			subtitle: 'Awesome coffee mug design.',
			imageUrl: '/images/portfolio-image-4.jpg',
			largeImageUrl: [
				'/images/portfolio-image-4-lg.jpg',
				'/images/portfolio-image-4-lg2.jpg',
			],
			url: 'https://facebook.com',
		},
		{
			id: 3,
			title: 'Tea & Coffee Mug',
			subtitle: 'Beautiful mug with logo.',
			imageUrl: '/images/portfolio-image-2.jpg',
			url: 'https://pinterest.com',
		},
		{
			id: 4,
			title: 'Pen Holder',
			subtitle: 'A pen holder with beautiful design.',
			imageUrl: '/images/portfolio-image-3.jpg',
			largeImageUrl: ['/images/portfolio-image-3-lg.jpg'],
			url: '#',
		},
		{
			id: 5,
			title: 'Mug',
			subtitle: 'Mug with awesome style',
			imageUrl: '/images/portfolio-image-5.jpg',
			largeImageUrl: ['/images/portfolio-image-5-lg.jpg'],
		},
		{
			id: 6,
			title: 'Pendrive',
			subtitle: 'Free pendrive mockup design.',
			imageUrl: '/images/portfolio-image-6.jpg',
			largeImageUrl: ['/images/portfolio-image-6-lg.jpg'],
		},
		{
			id: 7,
			title: 'Beautiful Pendrive',
			subtitle: 'Pendrive with great design & flexible.',
			imageUrl: '/images/portfolio-image-7.jpg',
			largeImageUrl: ['/images/portfolio-image-7-lg.jpg'],
			url: 'https://twitter.com',
		},
		{
			id: 8,
			title: 'Sticker',
			subtitle: 'Clip sticker mockup design.',
			imageUrl: '/images/portfolio-image-8.jpg',
			largeImageUrl: ['/images/portfolio-image-8-lg.jpg'],
		},
		{
			id: 9,
			title: 'Packet',
			subtitle: 'Beautiful packet & product design.',
			imageUrl: '/images/portfolio-image-9.jpg',
			largeImageUrl: ['/images/portfolio-image-9-lg.jpg'],
		},
		{
			id: 10,
			title: 'Pen Holder',
			subtitle: 'A pen holder with beautiful design.',
			imageUrl: '/images/portfolio-image-3.jpg',
			largeImageUrl: ['/images/portfolio-image-3-lg.jpg'],
			url: '#',
		},
		{
			id: 11,
			title: 'Coffee Mug',
			subtitle: 'Awesome coffee mug design.',
			imageUrl: '/images/portfolio-image-4.jpg',
			largeImageUrl: [
				'/images/portfolio-image-4-lg.jpg',
				'/images/portfolio-image-4-lg2.jpg',
			],
			url: 'https://facebook.com',
		},
		{
			id: 12,
			title: 'Tea & Coffee Mug',
			subtitle: 'Beautiful mug with logo.',
			imageUrl: '/images/portfolio-image-2.jpg',
			url: 'https://pinterest.com',
		},
		{
			id: 13,
			title: 'T-shirt Mockup',
			subtitle: 'A beautiful t-shirt mockup.',
			imageUrl: '/images/portfolio-image-1.jpg',
			largeImageUrl: ['/images/portfolio-image-1-lg.jpg'],
			url: 'https://dribbble.com',
		},
		{
			id: 14,
			title: 'Mug',
			subtitle: 'Mug with awesome style',
			imageUrl: '/images/portfolio-image-5.jpg',
			largeImageUrl: ['/images/portfolio-image-5-lg.jpg'],
		},
		{
			id: 15,
			title: 'Pendrive',
			subtitle: 'Free pendrive mockup design.',
			imageUrl: '/images/portfolio-image-6.jpg',
			largeImageUrl: ['/images/portfolio-image-6-lg.jpg'],
		},
		{
			id: 16,
			title: 'Beautiful Pendrive',
			subtitle: 'Pendrive with great design & flexible.',
			imageUrl: '/images/portfolio-image-7.jpg',
			largeImageUrl: ['/images/portfolio-image-7-lg.jpg'],
			url: 'https://twitter.com',
		},
		{
			id: 17,
			title: 'Sticker',
			subtitle: 'Clip sticker mockup design.',
			imageUrl: '/images/portfolio-image-8.jpg',
			largeImageUrl: ['/images/portfolio-image-8-lg.jpg'],
		},
		{
			id: 18,
			title: 'Packet',
			subtitle: 'Beautiful packet & product design.',
			imageUrl: '/images/portfolio-image-9.jpg',
			largeImageUrl: ['/images/portfolio-image-9-lg.jpg'],
		},
		{
			id: 19,
			title: 'T-shirt Mockup',
			subtitle: 'A beautiful t-shirt mockup.',
			imageUrl: '/images/portfolio-image-1.jpg',
			largeImageUrl: ['/images/portfolio-image-1-lg.jpg'],
			url: 'https://dribbble.com',
		},
		{
			id: 20,
			title: 'Coffee Mug',
			subtitle: 'Awesome coffee mug design.',
			imageUrl: '/images/portfolio-image-4.jpg',
			largeImageUrl: [
				'/images/portfolio-image-4-lg.jpg',
				'/images/portfolio-image-4-lg2.jpg',
			],
			url: 'https://facebook.com',
		},
		{
			id: 21,
			title: 'Tea & Coffee Mug',
			subtitle: 'Beautiful mug with logo.',
			imageUrl: '/images/portfolio-image-2.jpg',
			url: 'https://pinterest.com',
		},
		{
			id: 22,
			title: 'Pen Holder',
			subtitle: 'A pen holder with beautiful design.',
			imageUrl: '/images/portfolio-image-3.jpg',
			largeImageUrl: ['/images/portfolio-image-3-lg.jpg'],
			url: '#',
		},
		{
			id: 23,
			title: 'Mug',
			subtitle: 'Mug with awesome style',
			imageUrl: '/images/portfolio-image-5.jpg',
			largeImageUrl: ['/images/portfolio-image-5-lg.jpg'],
		},
	],
	experience: {
		workingExperience: [
			{
				id: 1,
				year: 'Apr 2020 - Mar 2022',
				position: 'Technical Lead',
				company: 'Srijan Technologies Pvt. Ltd',
				details:
					'I was assigned to a new role where my primary responsibility was to take care of the client deliverables and participating into the various architecture design discussion. Later on, I was actively particiapting into all the technical discussion and ensuring that the right and suitable technical solution was proposed for a given requirement.',
			},
			{
				id: 2,
				year: 'Jan 2018 - Apr 2020',
				position: 'Sr. Javascript Developer',
				company: 'Srijan Technologies Pvt. Ltd',
				details:
					'Initially, my responsibility was to take care of the client deliverables. And during this journey I have learnt & built several techinical and functional skills which was quite challenging but really helpful for my career growth.',
			},
			{
				id: 3,
				year: 'June 2015 - Jan 2018',
				position: 'IT ANALYST',
				company: 'Tata Consultancy Services',
				details:
					'Handled various enterprise clients. Automated the user’s journey of the used "Internal Audit" application using Selenium (Java). Built a time tracking application specific to the client’s account. I was actively workging on SQL Performance Tuning. I have a good knowledge of the MetricStream GRC Platform & Appstudio',
			},
			{
				id: 4,
				year: 'Sep 2014 - June 2015',
				position: 'ASSOCIATE TECHNICAL ANALYST',
				company: 'Oracle Financial Services Software Pvt Ltd',
				details:
					'Worked on application support and maintenance. Was assigned to work on the “Money Market” & “Security” module',
			},
			{
				id: 5,
				year: 'Mar 2012 - Sep 2014',
				position: 'MEMBER TECHNICAL STAFF',
				company: 'MetricStream Infotech (I) Pvt Ltd',
				details:
					'Initially involved on application support and maintenance. Worked with the Delivery team to work on the Internal Audit module e2e feature implementation for one of the enterprise clients. Worked on Jasper Report (iReport) to generate the report of supported type .docx and .pdf. Have a good knowledge of the MetricStream GRC Platform & Appstudio',
			},
		],
		educationExperience: [
			{
				id: 1,
				year: '2007 - 2011',
				graduation: 'Bachelor of Technology (Computer Science)',
				university: 'Ranchi University',
				details: '',
			},
			{
				id: 2,
				year: '2005 - 2007',
				graduation: 'St. Columba`s College',
				university: 'Ranchi University',
				details: '',
			},
			{
				id: 3,
				year: '2004 - 2005',
				graduation: 'Secondary Schooling',
				university: 'JAC Ranchi',
				details: '',
			},
		],
	},
	blogs: [
		{
			id: 1,
			title:
				'How to host a website with https enabled using AWS S3 and Cloudfront.',
			featuredImage: '/images/host-website-aws-s3-cloudfront.jpg',
			filesource: '../../blog/host-website-aws-s3-cloudfront.md',
			createDay: '28',
			createMonth: 'March',
			createYear: '2020',
		},
		{
			id: 2,
			title: 'Steps to Install & Setup Jenkins on EC2 Instance',
			featuredImage:
				'/images/steps-to-install-and-setup-jenkins-ec2-Instance.jpg',
			filesource:
				'../../blog/steps-to-install-and-setup-jenkins-ec2-Instance.md',
			createDay: '15',
			createMonth: 'May',
			createYear: '2020',
		},
		{
			id: 3,
			title: 'PostgreSQL vs MySQL performance benchmarking',
			featuredImage: '/images/blog-postgres-mysql-benchmarking.jpg',
			filesource:
				'../../blog/postgresql-vs-mysql-performance-benchmarking.md',
			createDay: '27',
			createMonth: 'April',
			createYear: '2020',
		},
		{
			id: 4,
			title: 'Sonarqube integration with Node.js app',
			featuredImage: '/images/sonarqube-integration-with-nodejs-app-v1.jpg',
			filesource: '../../blog/sonarqube-integration-with-nodejs-app.md',
			createDay: '20',
			createMonth: 'February',
			createYear: '2020',
		},
		{
			id: 5,
			title: 'Jira Utility for task creation flow automation',
			featuredImage: '/images/jira-utility-task-creation-automation-v1.jpg',
			filesource: '../../blog/jira-utility-task-creation-automation.md',
			createDay: '20',
			createMonth: 'June',
			createYear: '2021',
		},
		{
			id: 6,
			title: 'AWS EB Explained - Create an autoscalable EC2 instance',
			featuredImage: '/images/aws-eb.jpg',
			filesource: '../../blog/aws-eb-create-autoscalable-instance.md',
			createDay: '25',
			createMonth: 'June',
			createYear: '2021',
		},
		{
			id: 7,
			title: 'The Power of Integration Tests',
			featuredImage: '/images/integration-testing.jpg',
			filesource: '../../blog/power-of-integration-tests.md',
			createDay: '28',
			createMonth: 'August',
			createYear: '2021',
		},
		{
			id: 8,
			title: 'APIs scan through ZAP Explained',
			featuredImage: '/images/api-scan-zap.jpg',
			filesource: '../../blog/apis-scan-through-zap.md',
			createDay: '29',
			createMonth: 'December',
			createYear: '2021',
		},
		{
			id: 9,
			title: 'How to run multiple nodejs instances on multiple CPU cores',
			featuredImage: '/images/node-js-multi-threading.jpg',
			filesource: '../../blog/how-to-run-multiple-instances-of-nodejs-on-multi-core.md',
			createDay: '13',
			createMonth: 'February',
			createYear: '2022',
		},
		{
			id: 10,
			title: 'How Does Base64 character encoding works? Explained',
			featuredImage: '/images/base-64-character-encoding.png',
			filesource: '../../blog/base-64-encoding-algorithm.84c5d69e.md',
			createDay: '27',
			createMonth: 'February',
			createYear: '2022',
		},
	],
	contactInfo: {
		phoneNumbers: ['+91 7980735635', ''],
		emailAddress: ['servikash@gmail.com', ''],
		address: 'Bengaluru, India',
	},
};


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});